@import url('https://fonts.googleapis.com/css2?family=Special+Elite&family=Raleway:wght@300;400;600&display=swap');

:root {
    --primary-green: #6a824c;
    --secondary-green: #7a8e5a;
    --accent-green: #d3e1b8;
    --primary-brown: #a2784e;
    --secondary-brown: #b98d68;
    --accent-brown: #e2c8a8;
    --background-color: #e8e4d6;
    --text-color: #5b6142;
    --bubble-brown: #b5a086;
  }

  body {
    margin: 0;
    font-family: 'Raleway', sans-serif;
    background-color: var(--background-color);
    color: var(--text-color);
    line-height: 1.2;
  }

  header {
    background-color: var(--primary-green);
    color: var(--accent-brown);
    padding: 5px;
    text-align: center;
    font-size: 3em;
    font-weight: 600;
    font-family: 'Special Elite', cursive;
    border-bottom: 3px solid var(--primary-brown);
  }

  nav {
    background-color: var(--secondary-brown);
    padding: 0px 20px;
    display: flex;
    justify-content: center;
    gap: 25px;
    border-radius: 8px;
    max-width: 790px;
    margin: 10px auto;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }

  nav a {
    color: #f5e2d9;
    text-decoration: none;
    font-size: 1.4em;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 8px;
    transition: background-color 0.3s;
  }

  nav a:hover {
    background-color: var(--accent-green);
    color: var(--primary-green);
  }

  .hero-sub-text {
    font-family: 'Raleway', sans-serif; /* Match font */
    color: var(--primary-green); /* Match color */
    text-align: center;
    font-size: .7em;
    font-style: italic;
}

  .hero {
    text-align: center;
    background-image: url('');
    background-size: cover;
          background-position: center;
          color: var(--primary-brown);
          padding: 15px 15px;
          font-size: 1.6em;
          font-weight: bold;
          border-radius: 12px;
          margin: 20px auto 0px;
          max-width: 800px;
          font-family: 'Special Elite', cursive;
          position: relative;
          overflow: hidden;
          background-color: var(--bubble-brown);
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  .hero-text {
    font-family: 'Special Elite', cursive;
    line-height: .9;
    max-width: 100%;
    padding: 10px 10px;
    margin: 10px auto;
    font-weight: bold;
    background-color: rgba(255, 248, 240, 0.95);
    border-radius: 10px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    display: block;
  }
  
  .hero-main-text {
    position: relative;
    display: inline-block;
    background-color: rgba(255, 248, 240, 0.9);
    padding: 10px 15px;
    border-radius: 12px;
    color: var(--primary-brown);
    z-index: 1;
  }
  
  .search-bar {
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

  .search-bar input {
    padding: 5px;
    width: 62%;
    border: 2px solid var(--primary-green);
    border-radius: 8px;
    outline: none;
    font-size: 1em;
  }

  .search-bar button {
    padding: 10px 15px;
    margin-left: 10px;
    border: none;
    border-radius: 8px;
    background-color: var(--primary-green);
    color: white;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
.search-input-container {
display: flex;
justify-content: center;
gap: 10px; /* Spacing between input and button */
width: 80%;
}

.search-input-container input {
margin: 0; /* Ensures no offset due to margin */
}

  .search-bar button:hover {
    background-color: var(--secondary-green);
  }

  .map-button {
    text-align: center;
    margin: 20px 0;
  }

  .map-button button {
    padding: 10px 20px;
    font-size: 1em;
    background-color: var(--primary-brown);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }

  .map-button button:hover {
    background-color: var(--secondary-brown);
  }

  /* 
     This .content margin is now ~50% less than 5px (≈ 2.5px).
     Let's use 3px for a clean integer. Adjust as needed. 
  */
  .content {
    max-width: 1100px;
    margin: 0px auto;
    padding: 20px;
  }
a {
  color: var(--primary-green);
  text-decoration: none;
}

a:hover {
  color: var(--secondary-green);
  text-decoration: underline;
}

  .market-header {
    text-align: center;
    color: var(--primary-green);
    font-family: 'Raleway', sans-serif;
  }

  .market-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-content: center;
    justify-items: center;
    max-width: 60%;
    margin: auto;
  }

  .market-openMonth {
    color: green;
  }

  .market-distance {
    position: relative;
    bottom: 8px;
  }

  .market-card {
    background-color: var(--accent-brown);
    border-radius: 12px;
    text-align: center;
    border: 2px solid var(--primary-green);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    padding: 15px;
    padding-top: 0px;
    width: 100%;
    max-width: 250px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    word-wrap: break-word;
  }

  .market-card button {
    padding: 10px 15px;
    margin-top: 10px;
    border: none;
    border-radius: 8px;
    background-color: var(--primary-green);
    color: white;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 150px;
    margin: auto;
  }
  
  .market-card button:hover {
    background-color: var(--secondary-green);
  }

  .market-card img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: 8px;
  }

  .view-container {
    max-width: 1200px;
    margin: -15px auto;
    text-align: center;
  }

  .map-container {
    display: none; /* Hidden by default (View Map triggers it) */
  }

  .map-container iframe {
    width: 60%;
    height: 360px;
    border: none;
    border-radius: 15px;
  }

  .results-container {
    display: none;
    background-color: var(--accent-brown);
    border-radius: 8px;
    max-width: 80%;
    margin: 0px auto;
    padding: 10px 10px;
    color: var(--primary-green);
  }

  footer {
    background-color: var(--primary-green);
    color: var(--accent-brown);
    text-align: center;
    padding: 15px;
    margin-top: 20px;
  }

  @media screen and (max-width: 768px) {
    nav {
      flex-direction: column;
    }
    .market-list {
      grid-template-columns: 1fr;
    }
    .map-container iframe {
      height: 250px;
    }
  }
